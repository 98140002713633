import planLevels from '@/modules/plans/data/planLevels'

export default [
  {
    id: 1,
    type: 'light',
    priority_level: planLevels.light,
    description: {
      text: 'Recommended for users looking for informations about inputs',
      highlight: 'informations',
    },
    price: { month: 9.9 },
    features: [
      {
        id: 1,
        text: 'Fungicide catalog',
        included: true,
      },
      {
        id: 2,
        text: 'Soy and wheat variety catalog',
        included: true,
      },
      {
        id: 3,
        text: 'Nematicide catalog',
        included: true,
      },
      {
        id: 4,
        text: 'Simulation of fungicides for soybean and wheat',
        included: false,
      },
      {
        id: 5,
        text: 'Fungicide comparison',
        included: false,
      },
      {
        id: 6,
        text: 'Fungicide efficacies',
        included: false,
      },
      {
        id: 7,
        text: 'Ideal data for real-time application',
        included: false,
      },
      {
        id: 8,
        text: 'Monitoring productivity at +7 bags/ac',
        included: false,
      },
      {
        id: 9,
        text: 'FieldView integration',
        included: false,
      },
      {
        id: 10,
        text: 'Customized reports',
        included: false,
      },
    ],
    observations: ['1 user available, without simultaneous access'],
  },
  {
    id: 2,
    type: 'pro',
    priority_level: planLevels.pro,
    description: {
      text: 'Recommended for users looking to reduce costs when purchasing inputs',
      highlight: 'reduce costs',
    },
    price: { month: 299.9 },
    features: [
      {
        id: 1,
        text: 'Fungicide catalog',
        included: true,
      },
      {
        id: 2,
        text: 'Soy and wheat variety catalog',
        included: true,
      },
      {
        id: 3,
        text: 'Nematicide catalog',
        included: true,
      },
      {
        id: 4,
        text: 'Simulation of fungicides for soybean and wheat',
        included: true,
      },
      {
        id: 5,
        text: 'Fungicide comparison',
        included: true,
      },
      {
        id: 6,
        text: 'Fungicide efficacies',
        included: true,
      },
      {
        id: 7,
        text: 'Ideal data for real-time application',
        included: true,
      },
      {
        id: 8,
        text: 'Monitoring productivity at +7 bags/ac',
        included: false,
      },
      {
        id: 9,
        text: 'FieldView integration',
        included: false,
      },
      {
        id: 10,
        text: 'Customized reports',
        included: false,
      },
    ],
    observations: [
      'One user available, with the possibility of purchasing new accounts for the amount of $89.90 per user per month',
    ],
  },
  {
    id: 3,
    type: 'full',
    priority_level: planLevels.full,
    description: {
      text: 'Recommended for users looking to optimize the management of fungicides',
      highlight: 'management of fungicides',
    },
    price: {
      month: 209.9,
      monthlyHectare: 0.2,
    },
    features: [
      {
        id: 1,
        text: 'Fungicide catalog',
        included: true,
      },
      {
        id: 2,
        text: 'Soy and wheat variety catalog',
        included: true,
      },
      {
        id: 3,
        text: 'Nematicide catalog',
        included: true,
      },
      {
        id: 4,
        text: 'Simulation of fungicides for soybean and wheat',
        included: true,
      },
      {
        id: 5,
        text: 'Fungicide comparison',
        included: true,
      },
      {
        id: 6,
        text: 'Fungicide efficacies',
        included: true,
      },
      {
        id: 7,
        text: 'Ideal data for real-time application',
        included: true,
      },
      {
        id: 8,
        text: 'Monitoring productivity at +7 bags/ac',
        included: true,
      },
      {
        id: 9,
        text: 'FieldView integration',
        included: true,
      },
      {
        id: 10,
        text: 'Customized reports',
        included: true,
      },
    ],
    observations: [
      'One user available, with the possibility of purchasing new accounts for the amount of $89.90 per user per month',
    ],
  },
]
